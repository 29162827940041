import React from "react"

import feature from "../images/feature.png"
import youtube from "../images/kon-karampelas-peLs78VbU50-unsplash.jpg"
import twitch from "../images/caspar-camille-rubin-DrL-cwqD6tM-unsplash.jpg"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const Content = () => (
  <div style={{ padding: "2rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Bridging the creator-to-audience gap"
      description=""
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>Private and Public Streaming</h3>
        <p style={{ color: COLORS.gray }}>
          Authenticate your viewers with an email OTP, restrict by domain, subnet, or a simple password. We customise each stream to your needs.
        </p>
      </div>
      <div>
        <img src={feature} alt="Authentication UI" />
      </div>
    </content>
    <hr style={{
      borderColor: COLORS.gray,
      marginTop: "2rem",
      marginBottom: "4rem",
    }} />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <img src={youtube} alt="by Kon Karampelas on Unsplash" style={{ paddingRight: '2rem' }} />
      </div>
      <div>
        <h3>YouTube Live, Facebook Live, or wherever you want your content to Go Live.</h3>
        <p style={{ color: COLORS.gray }}>
          We even have customised platforms support. Using AWS Elemental? DaCast? Not sure which? We know the pros and cons of each. Get in touch with us and we can work out your live-streaming needs!
        </p>
      </div>
    </content>
    <hr style={{
      borderColor: COLORS.gray,
      marginTop: "3rem",
      marginBottom: "4rem",
    }} />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>Gaming or running a league?</h3>
        <p style={{ color: COLORS.gray }}>
          Members of our team have previously run gaming competitions, with live scoreboard overlays and score counters, we also have experienced programmers ready to integrate your game's plugins to handle real-time data.
        </p>
      </div>
      <div>
        <img src={twitch} alt="by Caspar Camille Rubin on Unsplash" style={{ paddingLeft: '2rem' }} />
      </div>
    </content>
  </div>
)

export default Content
