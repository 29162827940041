import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Not Convinced?"
      description="Reach us through the contact form of our company, Koa and Co!"
    />
    <a href="https://koaandco.com"><Button>Reach Out</Button></a>
  </div>
)

export default CallToAction
